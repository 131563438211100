import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/bootstrap-custom.css';
import '../css/menu-blu.css';

function Footer(props) {

    return (
        <div className="widewrapper">
            <div className="top" />
            <div className="header" />
            <div className="footer text-center">
                <h4 className="name">Gestione Tessere Fedeltà - Coderit s.r.l.</h4>
            </div>
        </div>
    );
};

export default Footer;