const users = [
    { role: "owner",
      tabs: [
        {id : "0", text : "lotti", url: "batches"},
        {id : "1", text : "tessere", url: "loyalty-cards"},
        {id : "2", text : "promozioni", url: "promos"},
        {id : "3", text : "statistiche", url: "stats"},
        {id : "4", text : "profilo", url: "profile"}
      ]
    },
    { role: "employee", 
      tabs: [
        {id : "0", text : "tessere", url: "loyalty-cards"},
        {id : "1", text : "profilo", url: "profile"}
      ]
    },
    { role: "admin", 
      tabs: [
        {id : "0", text : "negozi", url: "stores"},
        {id : "1", text : "statistiche", url: "stats"},
        {id : "2", text : "profilo", url: "profile"}
      ]
    }    
];

const encodings = ["EAN8", "EAN13", "Code39", "Code128", "ITF"];

export {users, encodings};