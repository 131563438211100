import React, {useEffect, useState} from 'react';
import { useKeycloak } from "@react-keycloak/web";

import Login from './Login';
import Home from './Home';

const PrivateRoute = () => {

    const logout = () => {
        if(keycloak.authenticated) {
            keycloak.logout();
            sessionStorage.clear();
        }
    };

    const [session, setSession] = useState({user: undefined, logout: logout});
    const { keycloak } = useKeycloak();
    
    useEffect(() => {    
        if(keycloak.authenticated) {
            const user = keycloak.idTokenParsed.preferred_username;
            setSession(session => ({
                user,
                logout: session.logout
            }));

            keycloak.onTokenExpired = () => {
                keycloak.updateToken(600)
                    .then(function(refreshed) {
                        if (refreshed) {
                            console.log('Token was successfully refreshed');
                        } else {
                            console.log('Token is still valid');
                        }
                    }).catch(function() {
                        console.log('Failed to refresh the token, or the session has expired');
                    });
            };
        }
    }, [keycloak.authenticated]);   
    
    return keycloak.authenticated ? <Home {...session} /> : <Login />;
};

export default PrivateRoute;