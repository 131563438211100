import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ReactKeycloakProvider} from "@react-keycloak/web";

import PrivateRoute from './components/PrivateRoute';
import {keycloak, initOptions} from './config/keycloak';

import './App.css';

function App() {

 return (
   <div>
     <ReactKeycloakProvider 
        authClient={keycloak}
        initOptions={initOptions}
        onTokens={({ token }) => {
          sessionStorage.setItem('token', token);
        }}>   
      <BrowserRouter>
        <Routes>
          <Route exact path="/*" element={<PrivateRoute />} />
        </Routes>
      </BrowserRouter>
     </ReactKeycloakProvider>     
   </div>
 );

}

export default App;
