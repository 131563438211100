import Keycloak from "keycloak-js";

// const KEYCLOAK_CONFIG = {
//     URL: "http://localhost:8080/",
//     REALM: "pucceria-realm",
//     CLIENT_ID: "pucceria-client"
// }

// const keycloak = new Keycloak({
//  url: `${KEYCLOAK_CONFIG.URL}`,
//  realm: `${KEYCLOAK_CONFIG.REALM}`,
//  clientId: `${KEYCLOAK_CONFIG.CLIENT_ID}`
// });

const keycloak = new Keycloak({
    url: window.URL,
    realm: window.REALM,
    clientId: window.CLIENT_ID
});

const initOptions = { 
    onLoad: 'check-sso',
    pkceMethod: "S256" 
};

export {keycloak, initOptions};