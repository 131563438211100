import React from 'react';
import { Button, Form, Toast } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import dayjs from 'dayjs';
import fileDownload from 'js-file-download'

import API from '../api/API';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

function BatchTable(props) {

    const NoDataIndication = () => (
        <div className="spinner">
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
    );

    const batches = props.batches.map(
        batch => {
            return {
                id: batch.id,
                name: batch.name,
                cardinality: batch.cardinality,
                //encoding: batch.encoding,
                date: dayjs(batch.creationDate).format('DD/MM/YYYY').toString()
            };
        }
    );

    const columns = [
        {
            dataField: "id",
            text: "ID",
            headerAlign: 'center',
            // sort: true
        },        
        {
            dataField: "name",
            text: "Nome",
            headerAlign: 'center',
            sort: true
        },
        {
            dataField: "cardinality",
            text: "Quantità",
            headerAlign: 'center'
        },
        // {
        //     dataField: "encoding",
        //     text: "Codifica",
        //     headerAlign: 'center'
        // },
        {
            dataField: "date",
            text: "Data",
            headerAlign: 'center',
            sort: true
        },      
        {
            dataField: "actions",
            text: "",
            headerAlign: 'center',
            formatter: actionFormatter
        }             
    ];   
    
    const handleBatchDownload = async (id, name) => {
        try {
                const fileName = `${name}.csv`;
                const batch = await API.downloadBatch(id);
                fileDownload(batch, fileName);
            } catch(err) {
                console.log(err);
            }
    }
    
    function actionFormatter(cell, row) {//, rowIndex, formatExtraData) {
        return (<>
                <Button size="xs" variant="primary">
                    <FontAwesomeIcon 
                        icon={faDownload}
                        size="1x" 
                        onClick={(event) => {
                            event.preventDefault();
                            handleBatchDownload(row.id, row.name);
                        }} 
                    />
                </Button>             
                {' '}                                         
                <Button size="xs" variant="danger">
                    <FontAwesomeIcon 
                        icon={faTrashAlt}
                        size="1x" 
                        onClick={(event) => {
                            event.preventDefault();
                            props.deleteBatch(row.id);
                        }} 
                    />
                </Button>
            </>);
      }

    const rowClasses = 'text-center';    
    
    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
      }];

      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Da { from } a { to } di { size } Risultati
        </span>
      );
      
      const options = {
        paginationSize: 5,
        pageStartIndex: 1,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: 'Tutti', value: batches.length
        }]
      };
      
    const { SearchBar } = Search;  
      
    return (
        <Form>
            <div className="col-lg-12 col-md-12">
                <fieldset>
                    <legend>
                        <h3 className='table-header'>Lista lotti</h3>
                    </legend>
                </fieldset>
                {props.loading ? <span><strong>🕗 Caricamento dei lotti in corso... 🕗</strong></span> : 
                ((props.batches && props.batches.length > 0) ?
                    <>
                        <ToolkitProvider
                            keyField="id"
                            data={batches}
                            columns={columns}                   
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar 
                                            { ...props.searchProps } 
                                            placeholder="Cerca"
                                        />
                                        <hr />
                                        <BootstrapTable
                                            { ...props.baseProps }
                                            pagination={paginationFactory(options)}
                                            defaultSorted={defaultSorted}
                                            rowClasses={rowClasses}
                                            noDataIndication={() => <NoDataIndication />} 
                                            bordered                    
                                            hover
                                            responsive
                                            striped 
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider> 
                        <Toast  bg={props.messageShow && props.message.type} 
                                show={props.messageShow && props.messageEvent === 'deleteBatch'} 
                                onClose={() => props.resetMessage()} 
                                delay={2000} 
                                autohide >
                            <Toast.Body>
                                <strong>{props.message.msg}</strong>
                            </Toast.Body>
                        </Toast>
                    </>
                    : console.log("Non ci sono lotti da mostrare")                        
                )}
            </div>
        </Form>
    );
};

export default BatchTable;