import React from 'react';
import { Button } from 'react-bootstrap/';
import { useKeycloak } from "@react-keycloak/web";

import 'bootstrap/dist/css/bootstrap.min.css';

import logoPucceria from '../images/pucceria.png';
import logoPucceliaca from '../images/pucceliaca.png';

function Login(props) {

    const { keycloak } = useKeycloak();      

    return (<>
        <div className="widewrapper">
            <div className="container content">
                <div className="row" style={{marginTop: '20px'}}>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-2" style={{visibility: "hidden"}}>
                        <img className="img-responsive img-thumbnail" style={{margin: "auto"}} src={logoPucceria} alt="Regione Siciliana"/>
                    </div>                    
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-2">
                        <img className="img-responsive img-thumbnail" style={{margin: "auto"}} src={logoPucceria} alt="Pucceria"/>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-2">
                        <img className="img-responsive img-thumbnail" style={{margin: "auto 10px"}} src={logoPucceliaca} alt="Pucceliaca"/>
                    </div>    
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-2" style={{visibility: "hidden"}}>
                        <img className="img-responsive img-thumbnail" style={{margin: "auto -10px"}} src={logoPucceliaca} alt="PO FESR Sicilia"/>
                    </div>                                                                                                
                </div>
        
                <div className="row text-center" style={{margin: '20px auto'}}>
                    <h2>GESTIONE TESSERE FEDELTÀ</h2>
                    <h4 style={{fontStyle: "italic"}}>Accedi per una migliore esperienza</h4>
                </div>
                
                <div className="text-center" style={{margin: '20px auto'}}>
                    <Button variant="primary"
                            style={{borderRadius: "0", color: "#fff"}}
                            size="lg"
                            onClick={() => keycloak.login()}>ACCEDI
                    </Button>
                </div>
            </div>
        </div>   
    </>     
    );
};

export default Login;

