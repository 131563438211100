
class Batch {
    // constructor(name, cardinality, encoding) {
    constructor(name, cardinality) {      
      this.name = name;
      this.cardinality = cardinality;
      //this.encoding = encoding;
    }

    static from(json) {
      const batch = new Batch();
      Object.assign(batch, json);
      return batch;
    }    
}

export {Batch};

  