import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { Navbar } from 'react-bootstrap';
import { users } from '../utils/data';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/bootstrap-custom.css';
import '../css/menu-blu.css';

import logoPucceria from '../images/pucceria_scaled.png';
import logoPucceliaca from '../images/pucceliaca_scaled.png';

function Header(props) {
    const [active, setActive] = useState("0");

    return (
        <div className="widewrapper">
            <div className="top"></div>
            <div className="header">   
                <Navbar collapseOnSelect 
                        fixed="top" 
                        className="navbar navbar-default my-navbar" 
                        role="navigation" 
                        expand="lg">
                    <Navbar.Brand>
                        <img className="d-inline-block align-top"
                             src={logoPucceria}                          
                             alt="Pucceria"/>
                        &nbsp;&nbsp;
                        <img className="d-inline-block align-top"
                             src={logoPucceliaca}                             
                             alt="Pucceliaca"/>                                                 
                    </Navbar.Brand>

                    <div className="collapse navbar-collapse navbar-responsive-collapse">
                            <ul className="nav navbar-nav navbar-right">
                                {
                                    users.filter(user => user.role === "owner")
                                    .map(user => user.tabs)
                                    .find(Boolean)
                                    .map(tab => {
                                        return (
                                            <li key={tab.id}
                                                className={active === tab.id ? "dropdown active" : "dropdown"}
                                                onClick={() => {setActive(tab.id);}}>
                                                <Link to={tab.url} className="dropdown-toggle" data-toggle="tab">
                                                    {tab.text.toUpperCase()}                                    
                                                </Link>
                                            </li>
                                        );
                                    })
                                }                                    
                            </ul>
                    </div>

                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <h3 className="name">
                                Benvenuto, <em>{props.user}</em>
                            </h3>
                            &nbsp;&nbsp;
                            <input type="button" 
                                   value="Logout"  
                                   onClick={props.logout}                                                      
                                   className="btn btn-xs btn-danger no-radius" />                            
                        </Navbar.Text>                        
                    </Navbar.Collapse>                    
                </Navbar>         
            </div>
        </div>      
    );

};

export default Header;