/**
 * All the API calls
 */

import { Batch } from "../models/Batch";

 //const BASEURL = "/api/v1";
 const BASEURL = window.BASE_URL;
 const BASEAUTH = "Bearer ";

 async function createBatch(batch) {
    // //POST /api/batches/
    const response = await fetch(BASEURL + '/batches/', {
      method: 'POST',
      headers: {                                
        'Content-Type': 'application/json',
        'Authorization': BASEAUTH + sessionStorage.getItem('token')
      },
      body: JSON.stringify(batch),                           
    });
    if (response.ok) {
      return {};
    } else {
      const json = await response.json();
      throw json;
    }   
  }

  async function deleteBatch(id) {
    // DELETE /api/batches/{id}
    const response = await fetch(BASEURL + '/batches/' + id, {
      method: 'DELETE',
      headers: {
        'Authorization': BASEAUTH + sessionStorage.getItem('token')
      }      
    });

    if (!response.ok) {
      throw 'Eliminazione lotto fallita';
    }
  }

  async function downloadBatch(id) {
    // GET /api/batches/{id}/download
    const response = await fetch(BASEURL + '/batches/' + id + '/download', {
      headers: {
        'Authorization': BASEAUTH + sessionStorage.getItem('token')
      }      
    });
    const batch = await response.text();

    if (response.ok) {
      return batch;
    } else {
      throw batch;
    }
  }

  async function getAllBatches() {
    // GET /api/batches/
    const response = await fetch(BASEURL + '/batches', {
                                  headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': BASEAUTH + sessionStorage.getItem('token')                                  
                                  },
                                });
                  
    const batchJson = await response.json();
    if (response.ok) {
      return batchJson.map(batch => Batch.from(batch));
    } else {
      throw batchJson;
    }
  }

  const API = { createBatch, deleteBatch, downloadBatch, getAllBatches };
  export default API;
