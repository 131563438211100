import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/bootstrap-custom.css';
import '../css/menu-blu.css';

function Main(props) {

    return (
        <div className="widewrapper weak-highlight page">
            <div className="col-lg-12 col-md-12 col-sm-8 col-xs-6" style={{ paddingTop: "10px" }}>
                <div className="panel panel-dark-blu no-radius" style={{ marginBottom: "5px" }}>
                    <div className="panel-heading no-radius">
                        <h3 className="panel-title">
                            <FontAwesomeIcon icon={faDashboard} />{' '}Pannello di controllo
                        </h3>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="panel-body" style={{ padding: "195px" }}></div>
            </div>      
        </div>
    );  
};

export default Main;