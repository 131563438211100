import React, { useState, useEffect } from 'react';
import { Route, Routes } from "react-router-dom";

import Header from './Header';
import BatchTab from './BatchTab';
import Main from './Main';
import Footer from './Footer';
import API from '../api/API';

function Home(props) {
    const [batches, setBatches] = useState([]);
    const [loading, setLoading] = useState(true);  
    const [messageShow, setMessageShow] = useState(false);
    const [messageEvent, setMessageEvent] = useState('');
    const [message, setMessage] = useState('');
    const [updateBatches, setUpdateBatches] = useState(false); 

    useEffect(()=> {
        const getBatches = async () => {
          const batchesList = await API.getAllBatches();
          setBatches(batchesList);
          setLoading(false);
        };

        getBatches()
        .catch(err => {
          console.error(err);
        });
    }, []);

    useEffect(()=> {
      const getBatches = async () => {
        const batchesList = await API.getAllBatches();
        setBatches(batchesList);
        setUpdateBatches(false);
      };
      if(updateBatches === true) {
        getBatches()
        .catch(err => {
          console.error(err);
        });
      }
    }, [updateBatches]); 

    const resetMessage = () => {
        setMessage({});
        setMessageEvent('');
        setMessageShow(false);
    };

      /**** Function to handle batch creation process ****/
      const createBatch = async (batch) => {
        setMessageShow(true);
        setMessageEvent('createBatch');
        try {
          const response = await API.createBatch(batch);
          setMessage({ msg: 'Creazione lotto avvenuta con successo', type: 'success' });
          setUpdateBatches(true);
        } catch(err) {
            setMessage({ msg: err.error, type: 'danger' });
            console.log(err);
        }
      };    
      
      /**** Function to handle batch deletion process ****/
      const deleteBatch = async (id) => {
        setMessageShow(true);
        setMessageEvent('deleteBatch');
        try {
          const response = await API.deleteBatch(id);
          setMessage({ msg: 'Elimazione lotto avvenuta con successo', type: 'success' });
          setUpdateBatches(true);
        } catch(err) {
            setMessage({ msg: err, type: 'danger' });
            console.log(err);
        }
      };        

    const batchProps = { 
      batches, 
      loading, 
      message, 
      messageEvent, 
      messageShow, 
      createBatch, 
      deleteBatch, 
      resetMessage 
    };

    return (
    <>
        <Header {...props} />

        <Routes>    
            <Route exact path="batches" element={<BatchTab {...batchProps} />} />
            <Route exact path="loyalty-cards" element={<Main />} />
            <Route exact path="promos" element={<Main />} />
            <Route exact path="stats" element={<Main />} />      
            <Route exact path="profile" element={<Main />} />
            <Route exact path="" element={<BatchTab {...batchProps} />} />
        </Routes> 

        <Footer />
    </>
    );
};

export default Home;