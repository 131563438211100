import React, {useState} from 'react';
import { Button, Col, Row, Form, FormGroup, Toast } from 'react-bootstrap';
// import Select from 'react-select';

import validator from 'validator';
// import { encodings } from '../utils/data';
import { Batch } from '../models/Batch';

function BatchForm(props) {
    const [errors, setErrors] = useState({});
    const [name, setName] = useState('');
    const [cardinality, setCardinality] = useState('');
    // const [selectedEncoding, setSelectedEncoding] = useState('');

    const findBatchFormErrors = () => {
        const newErrors = {};
        
        if (validator.isEmpty(name))
            newErrors.name = 'Nome non valido!';
        else if (validator.isEmpty(cardinality) || 
                    !validator.isNumeric(cardinality) ||
                    cardinality < 0)
            newErrors.cardinality = 'Quantità non valida!';
        // else if (!selectedEncoding)
        //     newErrors.selectedEncoding = 'Codifica non valida!';
    
        return newErrors;
    }
    
    const handleBatchFormSubmit = e => {
        e.preventDefault();
        // Form validation
        const newErrors = findBatchFormErrors();
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
          // We got errors!
          setErrors(newErrors);
        } else {
          // No errors!
        //   const batch = new Batch(name, cardinality, selectedEncoding);
          const batch = new Batch(name, cardinality);
          props.createBatch(batch);
        }
    }

    const handleResetClick = e => {
        e.preventDefault();

        setName('');
        setCardinality('');
        // setSelectedEncoding('');
    }    
    
    // const handleEncodingChange = (selectedOption) => {
    //     setSelectedEncoding(selectedOption);
    //     console.log(`Option selected:`, selectedOption);
    // }  

    // const encodingOptions = encodings.map(element => {
    //     return {
    //         label: `${element}`,
    //         value: `${element}`
    //     }
    // });   

    return (
        <Form onSubmit={handleBatchFormSubmit}>
            <div className="col-lg-9 col-md-9">
                <fieldset>
                    <legend>
                        <h3 className='table-header'>Creazione lotto</h3>
                    </legend>                                    
                    <FormGroup as={Row} className="mb-3">
                        <Col sm="12">
                            <Form.Label htmlFor="name">Nome</Form.Label>
                            <Form.Control type="text"
                                id="name"
                                className="form-control"
                                value={name}
                                placeholder="Nome"
                                style={{marginBottom: "10px", maxHeight: "40px", borderRadius: "0px"}}
                                onChange={(ev) => {
                                    if (!!errors["name"]) setErrors({
                                        ...errors,
                                        ["name"]: null
                                        });
                                    setName(ev.target.value);
                                }}
                                isInvalid={!!errors.name}                                           
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors.name}
                            </Form.Control.Feedback>                                        
                        </Col>                                                      
                    </FormGroup>
                    <FormGroup as={Row} className="mb-3">
                        <Col sm="12">
                            <Form.Label htmlFor="cardinality">Quantit&agrave;</Form.Label>
                            <Form.Control type="text"
                                id="cardinality"
                                className="form-control"
                                value={cardinality}
                                placeholder="Quantit&agrave;"
                                style={{marginBottom: "10px", maxHeight: "40px", borderRadius: "0px"}}
                                onChange={(ev) => {
                                    if (!!errors["cardinality"]) setErrors({
                                        ...errors,
                                        ["cardinality"]: null
                                        });
                                    setCardinality(ev.target.value);
                                }}
                                isInvalid={!!errors.cardinality}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors.cardinality}
                            </Form.Control.Feedback>
                        </Col>                                                                                                     
                    </FormGroup>                                                
                    {/* <FormGroup as={Row} className="mb-3">
                        <Col sm="12">
                            <Form.Label htmlFor="encoding">Codifica</Form.Label>
                            <Select
                                id="encoding"
                                value={selectedEncoding}
                                options={encodingOptions}      
                                onChange={handleEncodingChange}
                                isSearchable={true}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors.selectedEncoding}
                            </Form.Control.Feedback>
                        </Col>                                                                                                      
                    </FormGroup> */}
                    <Row>
                        <Col sm="12">
                            <Toast  bg={props.messageShow && props.message.type} 
                                    show={props.messageShow && props.messageEvent === 'createBatch'} 
                                    onClose={() => props.resetMessage()} 
                                    delay={2000} 
                                    autohide >
                                <Toast.Body>
                                    <strong>{props.message.msg}</strong>
                                </Toast.Body>
                            </Toast>
                        </Col>
                    </Row>
                </fieldset>
            </div>
            <div className="col-lg-9 col-md-9">
                <hr style={{borderColor : "#1abc9c"}} />
            </div>
            <div className="col-lg-6 col-lg-offset-2 col-md-6 col-md-offset-2">
                <Button variant="primary"
                    className="btn btn-danger no-radius"
                    onClick={handleResetClick}
                    type="button"
                    style={{marginBottom: "5px"}}>
                        RESET
                </Button>
                {' '}                                   
                <Button variant="primary"
                    className="btn btn-success no-radius"
                    type="submit"
                    style={{marginBottom: "5px"}}>
                        AGGIUNGI
                </Button>                                                                                                                                 
            </div>
        </Form>
        );
};

export default BatchForm;